import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body py-3" }
const _hoisted_2 = { class: "tab-content" }
const _hoisted_3 = {
  class: "tab-pane fade active show",
  id: "kt_table_widget_5_tab_1"
}
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4" }
const _hoisted_6 = { class: "text-end" }
const _hoisted_7 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2" }
const _hoisted_9 = { class: "text-end" }
const _hoisted_10 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = { class: "text-end" }
const _hoisted_13 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_14 = { class: "svg-icon svg-icon-2" }
const _hoisted_15 = { class: "text-end" }
const _hoisted_16 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_17 = { class: "svg-icon svg-icon-2" }
const _hoisted_18 = { class: "text-end" }
const _hoisted_19 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_20 = { class: "svg-icon svg-icon-2" }
const _hoisted_21 = {
  class: "tab-pane fade",
  id: "kt_table_widget_5_tab_2"
}
const _hoisted_22 = { class: "table-responsive" }
const _hoisted_23 = { class: "table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4" }
const _hoisted_24 = { class: "text-end" }
const _hoisted_25 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_26 = { class: "svg-icon svg-icon-2" }
const _hoisted_27 = { class: "text-end" }
const _hoisted_28 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_29 = { class: "svg-icon svg-icon-2" }
const _hoisted_30 = { class: "text-end" }
const _hoisted_31 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_32 = { class: "svg-icon svg-icon-2" }
const _hoisted_33 = {
  class: "tab-pane fade",
  id: "kt_table_widget_5_tab_3"
}
const _hoisted_34 = { class: "table-responsive" }
const _hoisted_35 = { class: "table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4" }
const _hoisted_36 = { class: "text-end" }
const _hoisted_37 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_38 = { class: "svg-icon svg-icon-2" }
const _hoisted_39 = { class: "text-end" }
const _hoisted_40 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_41 = { class: "svg-icon svg-icon-2" }
const _hoisted_42 = { class: "text-end" }
const _hoisted_43 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_44 = { class: "svg-icon svg-icon-2" }
const _hoisted_45 = { class: "text-end" }
const _hoisted_46 = {
  href: "#",
  class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
}
const _hoisted_47 = { class: "svg-icon svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _cache[51] || (_cache[51] = _createStaticVNode("<div class=\"card-header border-0 pt-5\"><h3 class=\"card-title align-items-start flex-column\"><span class=\"card-label fw-bolder fs-3 mb-1\">Latest Products</span><span class=\"text-muted mt-1 fw-bold fs-7\">More than 400 new products</span></h3><div class=\"card-toolbar\"><ul class=\"nav\"><li class=\"nav-item\"><a class=\"nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 active\" data-bs-toggle=\"tab\" href=\"#kt_table_widget_5_tab_1\">Month</a></li><li class=\"nav-item\"><a class=\"nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1\" data-bs-toggle=\"tab\" href=\"#kt_table_widget_5_tab_2\">Week</a></li><li class=\"nav-item\"><a class=\"nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4\" data-bs-toggle=\"tab\" href=\"#kt_table_widget_5_tab_3\">Day</a></li></ul></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("table", _hoisted_5, [
              _cache[20] || (_cache[20] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "border-0" }, [
                  _createElementVNode("th", { class: "p-0 w-50px" }),
                  _createElementVNode("th", { class: "p-0 min-w-150px" }),
                  _createElementVNode("th", { class: "p-0 min-w-140px" }),
                  _createElementVNode("th", { class: "p-0 min-w-110px" }),
                  _createElementVNode("th", { class: "p-0 min-w-50px" })
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                _createElementVNode("tr", null, [
                  _cache[0] || (_cache[0] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/plurk.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[1] || (_cache[1] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Brad Simmons"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Movie Creator")
                  ], -1)),
                  _cache[2] || (_cache[2] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "React, HTML", -1)),
                  _cache[3] || (_cache[3] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-success" }, "Approved")
                  ], -1)),
                  _createElementVNode("td", _hoisted_6, [
                    _createElementVNode("a", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/telegram.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[5] || (_cache[5] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Popular Authors"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Most Successful")
                  ], -1)),
                  _cache[6] || (_cache[6] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "Python, MySQL", -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-warning" }, "In Progress")
                  ], -1)),
                  _createElementVNode("td", _hoisted_9, [
                    _createElementVNode("a", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[8] || (_cache[8] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/vimeo.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[9] || (_cache[9] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "New Users"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Awesome Users")
                  ], -1)),
                  _cache[10] || (_cache[10] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "Laravel,Metronic", -1)),
                  _cache[11] || (_cache[11] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-primary" }, "Success")
                  ], -1)),
                  _createElementVNode("td", _hoisted_12, [
                    _createElementVNode("a", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[12] || (_cache[12] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/bebo.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[13] || (_cache[13] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Active Customers"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Movie Creator")
                  ], -1)),
                  _cache[14] || (_cache[14] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "AngularJS, C#", -1)),
                  _cache[15] || (_cache[15] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-danger" }, "Rejected")
                  ], -1)),
                  _createElementVNode("td", _hoisted_15, [
                    _createElementVNode("a", _hoisted_16, [
                      _createElementVNode("span", _hoisted_17, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[16] || (_cache[16] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/kickstarter.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[17] || (_cache[17] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Bestseller Theme"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Best Customers")
                  ], -1)),
                  _cache[18] || (_cache[18] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "ReactJS, Ruby", -1)),
                  _cache[19] || (_cache[19] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-warning" }, "In Progress")
                  ], -1)),
                  _createElementVNode("td", _hoisted_18, [
                    _createElementVNode("a", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("table", _hoisted_23, [
              _cache[33] || (_cache[33] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "border-0" }, [
                  _createElementVNode("th", { class: "p-0 w-50px" }),
                  _createElementVNode("th", { class: "p-0 min-w-150px" }),
                  _createElementVNode("th", { class: "p-0 min-w-140px" }),
                  _createElementVNode("th", { class: "p-0 min-w-110px" }),
                  _createElementVNode("th", { class: "p-0 min-w-50px" })
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                _createElementVNode("tr", null, [
                  _cache[21] || (_cache[21] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/plurk.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[22] || (_cache[22] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Brad Simmons"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Movie Creator")
                  ], -1)),
                  _cache[23] || (_cache[23] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "React, HTML", -1)),
                  _cache[24] || (_cache[24] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-success" }, "Approved")
                  ], -1)),
                  _createElementVNode("td", _hoisted_24, [
                    _createElementVNode("a", _hoisted_25, [
                      _createElementVNode("span", _hoisted_26, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[25] || (_cache[25] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/telegram.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[26] || (_cache[26] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Popular Authors"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Most Successful")
                  ], -1)),
                  _cache[27] || (_cache[27] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "Python, MySQL", -1)),
                  _cache[28] || (_cache[28] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-warning" }, "In Progress")
                  ], -1)),
                  _createElementVNode("td", _hoisted_27, [
                    _createElementVNode("a", _hoisted_28, [
                      _createElementVNode("span", _hoisted_29, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[29] || (_cache[29] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/bebo.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[30] || (_cache[30] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Active Customers"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Movie Creator")
                  ], -1)),
                  _cache[31] || (_cache[31] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "AngularJS, C#", -1)),
                  _cache[32] || (_cache[32] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-danger" }, "Rejected")
                  ], -1)),
                  _createElementVNode("td", _hoisted_30, [
                    _createElementVNode("a", _hoisted_31, [
                      _createElementVNode("span", _hoisted_32, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("table", _hoisted_35, [
              _cache[50] || (_cache[50] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "border-0" }, [
                  _createElementVNode("th", { class: "p-0 w-50px" }),
                  _createElementVNode("th", { class: "p-0 min-w-150px" }),
                  _createElementVNode("th", { class: "p-0 min-w-140px" }),
                  _createElementVNode("th", { class: "p-0 min-w-110px" }),
                  _createElementVNode("th", { class: "p-0 min-w-50px" })
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                _createElementVNode("tr", null, [
                  _cache[34] || (_cache[34] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/kickstarter.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[35] || (_cache[35] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Bestseller Theme"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Best Customers")
                  ], -1)),
                  _cache[36] || (_cache[36] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "ReactJS, Ruby", -1)),
                  _cache[37] || (_cache[37] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-warning" }, "In Progress")
                  ], -1)),
                  _createElementVNode("td", _hoisted_36, [
                    _createElementVNode("a", _hoisted_37, [
                      _createElementVNode("span", _hoisted_38, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[38] || (_cache[38] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/bebo.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[39] || (_cache[39] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Active Customers"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Movie Creator")
                  ], -1)),
                  _cache[40] || (_cache[40] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "AngularJS, C#", -1)),
                  _cache[41] || (_cache[41] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-danger" }, "Rejected")
                  ], -1)),
                  _createElementVNode("td", _hoisted_39, [
                    _createElementVNode("a", _hoisted_40, [
                      _createElementVNode("span", _hoisted_41, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[42] || (_cache[42] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/vimeo.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[43] || (_cache[43] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "New Users"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Awesome Users")
                  ], -1)),
                  _cache[44] || (_cache[44] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "Laravel,Metronic", -1)),
                  _cache[45] || (_cache[45] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-primary" }, "Success")
                  ], -1)),
                  _createElementVNode("td", _hoisted_42, [
                    _createElementVNode("a", _hoisted_43, [
                      _createElementVNode("span", _hoisted_44, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[46] || (_cache[46] = _createElementVNode("td", null, [
                    _createElementVNode("div", { class: "symbol symbol-45px me-2" }, [
                      _createElementVNode("span", { class: "symbol-label" }, [
                        _createElementVNode("img", {
                          src: "media/svg/brand-logos/telegram.svg",
                          class: "h-50 align-self-center",
                          alt: ""
                        })
                      ])
                    ])
                  ], -1)),
                  _cache[47] || (_cache[47] = _createElementVNode("td", null, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    }, "Popular Authors"),
                    _createElementVNode("span", { class: "text-muted fw-bold d-block" }, "Most Successful")
                  ], -1)),
                  _cache[48] || (_cache[48] = _createElementVNode("td", { class: "text-end text-muted fw-bold" }, "Python, MySQL", -1)),
                  _cache[49] || (_cache[49] = _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "badge badge-light-warning" }, "In Progress")
                  ], -1)),
                  _createElementVNode("td", _hoisted_45, [
                    _createElementVNode("a", _hoisted_46, [
                      _createElementVNode("span", _hoisted_47, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 2))
}